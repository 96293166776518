import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { getLoginSelector, getLangSelector } from "modules/Config/selectors";
import "react-notifications-component/dist/theme.css";
import "./styles/focalStyles.scss";
import { getPartnerSelector } from "modules/Partner/selectors";
import { dispatchSetPartner, dispatchSetPartnerLoading } from "modules/Partner/actions";
import { partnerApiLibraryGetPartnerById } from "partners/partnerUtils/apiLibrary";
import { apiLibraryGetClient } from "utils/apiLibrary/client";
import { dispatchUpdateAccount } from "modules/Account/actions";
import { dispatchLogin } from "modules/Config/actions";
import { SESSION_DEFAULT_LANG } from "utils/localStoreKeys";
import posthog from "posthog-js";

const { REACT_APP_PUBLIC_POSTHOG_HOST, REACT_APP_PUBLIC_POSTHOG_KEY } = process.env;
const initPostHog = () =>
  posthog.init(REACT_APP_PUBLIC_POSTHOG_KEY, {
    api_host: REACT_APP_PUBLIC_POSTHOG_HOST,
    persistence: 'cookie',
    secure_cookie: true,
    person_profiles: 'identified_only',
    capture_pageview: false,
  });
  
const PartnerHOC = ({ partner, setPartner, setPartnerLoading, children }) => {
  const dispatch = useDispatch();

  const extractTokenFromURL = (url) => {
    const tokenRegex = /(?:|\?)token=([a-zA-Z0-9]+)/;
    const match = url.match(tokenRegex);
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  };

  useEffect(() => {
    const getHomeWidgets = async () => {
      try {
        setPartnerLoading(true);
        const url = window.location.href;
        const tokenHash = extractTokenFromURL(url);
        if (tokenHash) {
          try {
            const responseAccount = await apiLibraryGetClient(`Bearer ${tokenHash}`);
            // logOut();
            localStorage.setItem("loginData", JSON.stringify(Object.assign({}, { token: tokenHash, name: "" }, responseAccount)));
            dispatch(dispatchUpdateAccount(responseAccount));
            dispatch(dispatchLogin(true));
          } catch (error) { }
        }

        const response = await partnerApiLibraryGetPartnerById();
        // if apiLibraryGetClient is called when auto-logged in, there's going to be 2 /client call.
        const tokenRegex = /(?:|\?)token=([a-zA-Z0-9]+)/;
        const bearerToken = localStorage.getItem("loginData")?.token;
        if ((window.location.href.match(tokenRegex)) || bearerToken) {
          await apiLibraryGetClient();
        }
        window.sessionStorage.setItem(SESSION_DEFAULT_LANG, response?.defaultLanguage)
        setPartner(response);
      } finally {
        initPostHog()
        setPartnerLoading(false);
      }
    };
    !partner && getHomeWidgets();
  }, [dispatch, partner, setPartner, setPartnerLoading]);
  return partner ? children : null;
};

const mapStateToProps = (state) => ({
  isLogged: getLoginSelector(state),
  lang: getLangSelector(state),
  partner: getPartnerSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  setPartner: (partner) => {
    dispatch(dispatchSetPartner(partner));
  },
  setPartnerLoading: (partnerLoading) => {
    dispatch(dispatchSetPartnerLoading(partnerLoading));
  },
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(PartnerHOC));
